import {useRef} from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import ScrollToTop from './ScrollToTop';
import { Carousel } from 'bootstrap';
import BCarousel from './BCarousel';
import {Typewriter} from 'react-simple-typewriter'


function StudentPanel(){

    

  const home =useRef(null);
  const aboutus =useRef(null);
  const contact =useRef(null);
  const noti =useRef(null);
  const others =useRef(null);

  const scrollToSection = (elementRef) => {
    window.scrollTo({
      top: elementRef.current.offsetTop,
      behaviour:"smooth",
    });
  }


  return (
    <>
    
    <div style={{display:'block'}}>
            <nav class="navbar navbar-expand-lg navbar navbar-dark bg-dark">
                <div class="container-fluid">
                    {/* remember to replace below img with acm logo */}
                <img src="Screenshot 2023-03-20 220217.png" alt="img" width="80" height="80"></img>
                    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button>
                    <div class="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                            <li class="nav-item">
                                <a class="nav-link " aria-current="page" onClick={() => scrollToSection(home)} style={{paddingLeft:"40px",paddingRight:"10px"}}>HOME</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link "  onClick={() => scrollToSection(aboutus)} style={{paddingLeft:"30px",paddingRight:"10px"}}>ABOUT US</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link " aria-current="page" onClick={() => scrollToSection(contact)} style={{paddingLeft:"40px",paddingRight:"10px"}}>CONTACT</a>
                            </li>


                            <li class="nav-item dropdown">
                                <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false" style={{paddingLeft:"30px",paddingRight:"10px"}}>
                                    MORE
                                </a>
                                <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                                    <li><a class="dropdown-item" onClick={() => scrollToSection(noti)}>NOTIFICATIONS</a></li>
                                    <li><a class="dropdown-item" onClick={() => scrollToSection(others)}>OTHERS</a></li>
                                    <li><hr class="dropdown-divider"></hr></li>
                                    
                                </ul>
                            </li>
                            


                            
                            
                        </ul>
                        <form class="d-flex">
                            <input class="form-control me-2" type="search" placeholder="Search" aria-label="Search" />
                            <button class="btn btn-outline-success" type="submit">Search</button>
                        </form>
                    </div>
                </div>
            </nav>
            </div>


    
    <div className="homme" ref={home}>
    <ScrollToTop />
        <div class="row">
            <h1 className='headfont' style={{marginTop:"6px",marginBottom:"6px",color:"black"}}>HOME</h1>
            <hr className="hr" style={{height:"3px",background:"solid black",marginBottom:"10px"}}></hr>

            <div  class="marquee">
            <marquee behavior="alternate"></marquee>
            </div>
            <img className='mainimg' src='College_SIT.jpg'></img>
            <h3 class="span" style={{position:"absolute" , color:"black" ,textAlign:"center", marginTop:"300px" ,fontSize:"50px" ,color:"black"}}> Association for Computing Machinery<br></br>
            <span  style={{color:"black"}}><b>
            <Typewriter 
                words={['Aspiration is the heart and soul of progress and innovation', 'The power of technology can help transform aspirations into realities', 'The thirst for knowledge is the driving force behind personal and societal progress', 'Technology is a tool that can be harnessed to achieve goals and make a difference','The pursuit of excellence is a journey that starts with a single step','With technology, anything is possible']}
                loop={5}
                
                cursor
                cursorStyle='_'
                typeSpeed={70}
                deleteSpeed={50}
                delaySpeed={1000}
                
            /></b>
            </span>
            </h3>        
        </div>
    </div>
    
    <div className='aboutus' ref={aboutus}>
        <h1 className='headfont' style={{marginTop:"10px",marginBottom:"10px",color:"black"}}>ABOUT US</h1>
        <hr className="hr" style={{height:"3px",background:"black"}}></hr>
        
        <BCarousel />
    </div>
    <div className='contact' ref={contact}>
        <h1 className='headfont' style={{marginTop:"10px",marginBottom:"10px",color:"black"}}>CONTACT</h1>
        <hr className="hr" style={{height:"3px",background:"black"}}></hr>
    </div>
    <div className='noti' ref={noti}>
        <h1 className='headfont' style={{marginTop:"10px",marginBottom:"10px",color:"black"}}>NOTIFICATIONS</h1>
        <hr className="hr" style={{height:"3px",background:"black"}}></hr>
    </div>
    <div className='others' ref={others}>
        <h1 className='headfont' style={{marginTop:"10px",marginBottom:"10px",color:"black"}}>OTHERS</h1>
        <hr className="hr" style={{height:"3px",background:"black"}}></hr>
    </div>
    </>
  )
}

export default StudentPanel;