import React, { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';

function BCarousel() {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  return (
    <><h3 className='font' style={{color:"white"}}>
      <b>Our Events</b>
    </h3>
    <Carousel activeIndex={index} onSelect={handleSelect} variant="dark">
      <Carousel.Item>
        <div className='caimag'>
          <img
            className="d-block "
            src="photo_2023-03-15_22-10-07.jpg"
            alt="Tech Mahindra"
          />
        </div>
      </Carousel.Item>

      <Carousel.Item>
        <div className='caimag'>
          <img
            className="d-block"
            src="photo_2023-03-15_22-10-03.jpg"
            alt="Second slide"
          />
        </div>
      </Carousel.Item>

      <Carousel.Item>
        <div className='caimag'>
          <img
            className="d-block"
            src="photo_2023-03-15_22-10-06.jpg"
            alt="Third slide"
          />
        </div>
      </Carousel.Item>        
    </Carousel></>
  );
}

export default BCarousel;