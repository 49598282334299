import React from 'react'
import StudentPanel from './components/StudentPanel'

const App = () => {
  return (
    <div>
      <StudentPanel />
    </div>
  )
}

export default App